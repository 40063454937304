import * as React from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import { useTranslate, MenuItemLink } from 'react-admin';
import AccountTreeIcon from '@material-ui/icons/AccountTree';


const Menu = ({ onMenuClick, logout }) => {
    const translate = useTranslate();
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    return (
        <div>
            <MenuItemLink
                to="/service-types"
                primaryText={translate(`evodent.resources.service-types.name`)}
                leftIcon={<AccountTreeIcon />}
                onClick={onMenuClick}
                sidebarIsOpen={open}
            />
            {isXSmall && logout}
        </div>
    );
};

export default Menu;