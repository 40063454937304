import * as React from "react";
import { createBrowserHistory as createHistory } from 'history';
import ReactDOM from "react-dom";
import { Admin, Resource } from 'react-admin';
import restProvider from 'ra-data-simple-rest';
import apiClient from '~/services/api-client';
import AuthProvider from '@develnet/authentication';
import urls from '~/services/urls';
import i18nProvider from '~/services/i18nProvider';
import Layout from '~/components/Layout';
import ServiceTypes from '~/components/ServiceTypes';
import customRoutes from '~/components/Routes';

const dataProvider = restProvider(urls.apiBase);
const history = createHistory();
const App = () => {
    return (
        <Admin 
        dashboard={ServiceTypes}
        dataProvider={dataProvider}
        authProvider={new AuthProvider(apiClient)}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
        history={history}
        disableTelemetry
        layout={Layout}>
            <Resource name='service-types' show={ServiceTypes} />
        </Admin>
    )
}

export default App;

if (document.getElementById('app')) {
    ReactDOM.render(<App />, document.getElementById('app'));
}
