import React from 'react';
import { Container, Box, Card } from '@material-ui/core';
import { InheritanceTreeEditorDashboard, PropertyTypesObject, TextPropertyType, NumberPropertyType } from '@develnet/inheritance-tree';

const ServiceTypes = (props) => {
    return (<>
        <Container maxWidth="xl">
            <InheritanceTreeEditorDashboard propertyTypes={new PropertyTypesObject([TextPropertyType, NumberPropertyType])}/>
        </Container>
    </>);
}

export default ServiceTypes;