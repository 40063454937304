import polyglotI18nProvider from 'ra-i18n-polyglot';
import lodashGet from 'lodash/get';
import lodashMerge from 'lodash/merge'
import reactAdminHu from 'ra-language-hungarian';
import evodentHu from '~/languages/hu.json';
import { translationHu as inheritanceTreeHu } from '@develnet/inheritance-tree';

const defaultLocale = 'hu';
const translations = {
    'hu': getHungarianTranslation(),
}

const i18nProvider = polyglotI18nProvider(locale => {
        return lodashGet(translations, locale, translations[defaultLocale]);
    },
    defaultLocale
);

function getHungarianTranslation() {
    return mergeAll([reactAdminHu, evodentHu, inheritanceTreeHu]);
}

function mergeAll(objects) {
    if (objects.length == 0) {
        return {};
    }

    var merged = objects.shift();
    for (var obj of objects) {
        merged = lodashMerge(merged, obj);
    }
    return merged;
}

export default i18nProvider;